<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import store from './store';

export default ({
  mounted() {
    if (!localStorage.getItem('theme')) {
      store.dispatch('themeSet', "dark");
      document.querySelector("html").classList.add("dark");
      document.querySelector("#app").classList.add("darkMode");
    } else if(localStorage.getItem('theme') == "dark") {
      document.querySelector("html").classList.add("dark");
      document.querySelector("#app").classList.add("darkMode");
    }
  },
})
</script>

<style>
  #app {
        min-height: 100vh;
        position: relative;
        -webkit-tap-highlight-color: transparent;
  }
  #app.darkMode:after{
    display: block;
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, #261710 43.23%, #3E2C0E 100%);
  }
  ::-webkit-scrollbar {
      display: none;
  }
</style>